import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ title, options, onSelect, theme }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative hidden lg:block md:block" ref={dropdownRef}>
            <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={`p-2 rounded-md ${theme.text} ${theme.background} hover:text-secondary`}
            >
                {title}
            </button>
            {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <ul>
                        {Object.keys(options).map((optionName) => (
                            <li
                                key={optionName}
                                onClick={() => {
                                    onSelect(optionName);
                                    setDropdownOpen(false);
                                }}
                                className="block px-4 py-2 text-black hover:bg-gray-200 cursor-pointer"
                            >
                                {optionName.charAt(0).toUpperCase() + optionName.slice(1)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const ThemeSwitcher = ({ themes, theme, changeTheme, particleOptions }) => {
    return (
        <div className='flex justify-end'>
            <Dropdown
                title="Change Theme"
                options={themes}
                onSelect={(themeName) => changeTheme(themeName, null)}
                theme={themes[theme]}
            />
            <Dropdown
                title="Particles"
                options={particleOptions}
                onSelect={(optionName) => changeTheme(null, optionName)}
                theme={themes[theme]}
            />
        </div>
    );
};

export default ThemeSwitcher;
