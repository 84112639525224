import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from '../context/ThemeContext';
import { certificates } from '../data/certificates';

// Modal animation variants
const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: "spring", stiffness: 300, damping: 25 }
    }
};

// Animation for certificate grid and individual cards
const gridVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { when: "beforeChildren", staggerChildren: 0.2 }
    }
};

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 100, damping: 15 }
    }
};

const Certificates = () => {
    const { theme, themes } = useContext(ThemeContext);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const handleOpenModal = (certificate) => {
        setSelectedCertificate(certificate);
    };

    const handleCloseModal = () => {
        setSelectedCertificate(null);
    };

    return (
        <div className='pt-3'>
            <h1 className={`text-3xl text-center mb-8 ${themes[theme].text}`}>My Certificates</h1>

            {/* Certificates Grid with stagger animation */}
            <motion.div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                initial="hidden"
                animate="visible"
                variants={gridVariants} // Stagger the animation of each certificate card
            >
                {certificates.map((certificate) => (
                    <motion.div
                        key={certificate.id}
                        className="border-2 border-yellow-400 rounded-lg p-4 shadow-lg flex flex-col items-center cursor-pointer"
                        whileHover={{ scale: 1.05 }}
                        variants={cardVariants} // Animate each card individually
                        onClick={() => handleOpenModal(certificate)}
                    >
                        <motion.img
                            src={certificate.image}
                            alt={certificate.title}
                            className="w-full h-64 object-cover rounded-lg mb-4"
                            loading='eager'
                        />
                        <div className="text-center">
                            <h3 className={`text-xl font-bold ${themes[theme].text}`}>
                                {certificate.title}
                            </h3>
                            <p className={`${themes[theme].subText}`}>
                                {certificate.description}
                            </p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>

            {/* Modal for Enlarged Certificate */}
            <AnimatePresence>
                {selectedCertificate && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-gray bg-opacity-75 z-50"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={modalVariants}
                        onClick={handleCloseModal}
                    >
                        <motion.div
                            className="relative p-4 bg-black bg-opacity-75 rounded-lg object-contain"
                            style={{
                                width: '90vw',
                                height: '90vh',
                                maxWidth: '1200px',
                                maxHeight: '90vh',
                                overflow: 'hidden',
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <img
                                src={selectedCertificate.image}
                                alt={selectedCertificate.title}
                                className="w-full h-full object-contain rounded-lg"
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Certificates;
