import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../context/ThemeContext';
import ZoominAppLogo from '../components/ZoominAppLogo';
import { projects } from '../data/projects'; // Import the projects array
import CourseProjects from './CourseProjects';

// Variants for the page container animation
const pageVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { when: "beforeChildren", staggerChildren: 0.3 }
    }
};

const Projects = () => {
    const { theme, themes } = useContext(ThemeContext);

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={pageVariants} // Animate the entire page container
            style={{ backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '5px' }}
            className={`min-h-screen flex flex-col items-center justify-start pt-3`} // Moved content to the top with padding
        >
            <h1 className={`text-3xl mb-8 ${themes[theme].text}`}>My Projects</h1>
            <motion.div
                className="flex flex-wrap justify-center gap-6"
                initial="hidden"
                animate="visible"
                variants={pageVariants} // Stagger the animation of each project card
            >
                {projects.map((project, index) => (
                    <ZoominAppLogo
                        key={index}
                        projectId={project.id}
                        imgSrc={project.imgSrc}
                        title={project.title}
                        isPremium={project.isPremium}
                    />
                ))}
            </motion.div>
            <CourseProjects></CourseProjects>
        </motion.div >
    );
};

export default Projects;
