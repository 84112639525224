import React from 'react';
import { motion } from 'framer-motion';

const CodesiosLogo = () => {
    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='hover:text-primary'
        >
            <a
                href="https://www.codesios.com" // External link to Codesios
                target="_blank" // Opens in a new tab
                rel="noopener noreferrer" // Security feature for external links
                className="text-2xl font-bold"
            >
                Codesios
            </a>
        </motion.div>
    );
};

export default CodesiosLogo;
