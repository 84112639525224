
// Define the available themes with smoother gradient backgrounds and 3D model colors
export const particleOptions = {
    none: {

    },
    links: {
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 3,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 80,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
    },
    coolLinks: {
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 2000,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 80,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
    },
    amongUs: {
        key: "amongUs",
        name: "Among Us",
        particles: {
            groups: {
                z5000: {
                    number: {
                        value: 70,
                    },
                    zIndex: {
                        value: 50,
                    },
                },
                z7500: {
                    number: {
                        value: 30,
                    },
                    zIndex: {
                        value: 75,
                    },
                },
                z2500: {
                    number: {
                        value: 50,
                    },
                    zIndex: {
                        value: 25,
                    },
                },
                z1000: {
                    number: {
                        value: 40,
                    },
                    zIndex: {
                        value: 10,
                    },
                },
            },
            number: {
                value: 200,
            },
            color: {
                value: "#fff",
                animation: {
                    enable: false,
                    speed: 20,
                    sync: true,
                },
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 1,
            },
            size: {
                value: 2,
            },
            move: {
                angle: {
                    value: 10,
                    offset: 0,
                },
                enable: true,
                speed: 5,
                direction: "right",
            },
            zIndex: {
                value: 5,
                opacityRate: 0.5,
            },
        },
        emitters: {
            position: {
                y: 55,
                x: -5,
            },
            rate: {
                delay: 7,
                quantity: 1,
            },
            size: {
                width: 0,
                height: 0,
            },
            particles: {
                shape: {
                    type: "images",
                    options: {
                        images: {
                            src: "https://particles.js.org/images/cyan_amongus.png",
                            width: 500,
                            height: 634,
                        },
                    },
                },
                size: {
                    value: 40,
                },
                move: {
                    speed: 10,
                    outModes: {
                        default: "none",
                        right: "destroy",
                    },
                    straight: true,
                },
                zIndex: {
                    value: 0,
                },
                rotate: {
                    value: {
                        min: 0,
                        max: 360,
                    },
                    animation: {
                        enable: true,
                        speed: 10,
                        sync: true,
                    },
                },
            },
        },
    },
    universe: {
        key: "absorbers",
        name: "Absorbers",
        particles: {
            number: {
                value: 200,
            },
            collisions: {
                enable: true,
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: {
                    min: 0.5,
                    max: 3,
                },
            },
            size: {
                value: {
                    min: 0.1,
                    max: 2,
                },
            },
            move: {
                enable: true,
                speed: 1,
                direction: "bottom",
                straight: true,
                warp: true,
            },
        },
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                push: {
                    quantity: 10,
                },
            },
        },
        absorbers: {
            draggable: true,
            size: {
                value: {
                    min: 5,
                    max: 10,
                },
                limit: 10,
            },
            position: {
                x: 50,
                y: 50,
            },
        }
    },
    colorballs: {
        key: "blackHole",
        name: "Black Hole",
        particles: {
            number: {
                value: 1000,
                density: {
                    enable: true,
                },
            },
            color: {
                value: ["#ffffff", "#77ccff", "#ff3333", "#ffff33"],
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 1,
            },
            size: {
                value: {
                    min: 1,
                    max: 1,
                },
            },
            links: {
                enable: false,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 0.5,
                warp: true,
            },
        },
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
            },
        },
        absorbers: {
            orbits: false,
            destroy: true,
            size: {
                value: 0.05,
                limit: 0.5,
                density: 50,
            },
            position: {
                x: 50,
                y: 50,
            },
        },
    },
    bubble: {
        key: "bubble",
        name: "Bubble",
        particles: {
            number: {
                value: 6,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#1b1e34",
            },
            shape: {
                type: "polygon",
                options: {
                    polygon: {
                        sides: 6,
                    },
                },
            },
            opacity: {
                value: {
                    min: 0.3,
                    max: 0.5,
                },
            },
            size: {
                value: {
                    min: 100,
                    max: 160,
                },
            },
            links: {
                enable: false,
                distance: 200,
                color: "#ffffff",
                opacity: 1,
                width: 2,
            },
            move: {
                enable: true,
                speed: 8,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "bubble",
                },
                onClick: {
                    enable: false,
                    mode: "push",
                },
            },
            modes: {
                grab: {
                    distance: 400,
                    links: {
                        opacity: 1,
                    },
                },
                bubble: {
                    distance: 400,
                    duration: 2,
                    size: 40,
                    opacity: 0.8,
                    color: "#ff0000",
                    mix: true,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    quantity: 4,
                },
                remove: {
                    quantity: 2,
                },
            },
        },
    },
    mask: {
        key: "backgroundMask",
        name: "Background Mask",
        particles: {
            number: {
                value: 20,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.1,
            },
            size: {
                value: {
                    min: 1,
                    max: 30,
                },
            },
            links: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 1,
                width: 1,
            },
            move: {
                enable: true,
                speed: 1,
                direction: "none",
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "bubble",
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                bubble: {
                    distance: 400,
                    size: 10,
                    duration: 2,
                    opacity: 0.1,
                },
                push: {
                    quantity: 4,
                },
            },
        },
        backgroundMask: {
            enable: false,
            cover: {
                color: {
                    value: {
                        r: 255,
                        g: 255,
                        b: 255,
                    },
                },
            },
        },
    },
    maskII: {
        key: "backgroundMask",
        name: "Background Mask",
        particles: {
            number: {
                value: 200,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.01,
            },
            size: {
                value: {
                    min: 10,
                    max: 10,
                },
            },
            links: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.1,
                width: 2,
            },
            move: {
                enable: true,
                speed: 1,
                direction: "none",
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "bubble",
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                bubble: {
                    distance: 100,
                    size: 10,
                    duration: 2,
                    opacity: 1,
                },
                push: {
                    quantity: 4,
                },
            },
        },
        backgroundMask: {
            enable: false,
            cover: {
                color: {
                    value: {
                        r: 255,
                        g: 255,
                        b: 255,
                    },
                },
            },
        },
    },
    redLinks: {
        key: "basic",
        name: "Basic",
        particles: {
            number: {
                value: 80,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ff0000",
                animation: {
                    enable: true,
                    speed: 20,
                    sync: true,
                },
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.5,
            },
            size: {
                value: {
                    min: 1,
                    max: 3,
                },
            },
            links: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 6,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                repulse: {
                    distance: 200,
                },
                push: {
                    quantity: 4,
                },
            },
        },
    },
    noisePlanes: {
        key: "noisePlanes",
        name: "Noise Planes",
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                    speed: 3,
                },
                grab: {
                    distance: 400,
                    links: {
                        opacity: 1,
                    },
                },
                push: {
                    quantity: 100,
                },
                remove: {
                    quantity: 2,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            move: {
                path: {
                    enable: true,
                    options: {
                        size: 32,
                        draw: false,
                        increment: 0.004,
                    },
                    generator: "simplexNoise",
                },
                enable: true,
                speed: 6,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 20,
            },
            rotate: {
                value: 45,
                path: true,
            },
            opacity: {
                value: 1,
            },
            shape: {
                options: {
                    image: {
                        height: 128,
                        src: "https://particles.js.org/images/plane_alt.png",
                        width: 128,
                    },
                },
                type: "image",
            },
            size: {
                value: 10,
            },
            zIndex: {
                value: {
                    min: 1,
                    max: 100,
                },
                opacityRate: 0,
                sizeRate: 2,
                velocityRate: 2,
            },
        },
    },
    parallax: {
        key: "parallax",
        name: "Parallax",
        particles: {
            number: {
                value: 100,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: {
                    min: 0.1,
                    max: 0.5,
                },
                animation: {
                    enable: true,
                    speed: 3,
                    sync: false,
                },
            },
            size: {
                value: {
                    min: 1,
                    max: 10,
                },
                animation: {
                    enable: true,
                    speed: 20,
                    sync: false,
                },
            },
            links: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 2,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "grab",
                    parallax: {
                        enable: true,
                        smooth: 10,
                        force: 60,
                    },
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                grab: {
                    distance: 400,
                    links: {
                        opacity: 1,
                    },
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 0.8,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    quantity: 4,
                },
                remove: {
                    quantity: 2,
                },
            },
        },
    }
};