import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import DownloadAppButton from '../components/DownloadAppButton';
import { projects } from '../data/projects'; // Import the static project list
import React, { useContext, useState, useEffect } from 'react';

// Modal animation variants
const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: "spring", stiffness: 300, damping: 25 }
    },
    exit: {
        opacity: 0,
        scale: 0.8,
        transition: { duration: 0.3 }
    }
};
// Stagger animation for the project grid
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2, // Adds a delay between each child animation
        },
    },
};
const ProjectDetails = () => {

    const { id } = useParams(); // Get project ID from URL
    const { theme, themes } = useContext(ThemeContext);
    const [project, setProject] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [selectedProj, setSelectedProj] = useState(null);

    const handleOpenModal = (proj) => {
        setSelectedProj(proj);
    };

    const handleCloseModal = () => {
        setSelectedProj(null);
    };

    // Use an effect to track the window size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 640); // sm breakpoint in Tailwind is 640px
        };

        // Set the initial value
        handleResize();

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        // Find the project from the static list
        const selectedProject = projects.find(proj => proj.id === parseInt(id));
        setProject(selectedProject);
    }, [id]);

    if (!project) return <div className='text-center text-3xl mt-14 pt-14'>Project not found...</div>;

    const { detailPage } = project;

    return (
        <div className="p-8" style={{ color: themes[theme].text }}>
            {/* Center-aligned logo and title */}
            <div className="text-center">
                <div className="inline-block text-center mb-5">
                    <h1 className="text-4xl font-bold mb-2 px-4">{detailPage.title}</h1>
                    <div className="h-2 rounded-full w-full mt-3" style={{ backgroundColor: themes[theme].mainTint }}></div>
                </div>
                <br></br>
                <motion.img
                    src={project.imgSrc}
                    alt={project.title}
                    className="img mx-auto"
                    style={{ borderRadius: '25%', width: '150px', height: '150px', cursor: 'pointer' }}
                />
                <h1 className="text-xl font-bold mb-5 mt-5">{detailPage.logoText}</h1>
                {detailPage.appStoreLink && <DownloadAppButton appStoreLink={detailPage.appStoreLink} />}
            </div>

            {/* Images With Text */}
            <motion.div
                className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                {detailPage.imagesWithText && detailPage.imagesWithText.length > 0 && detailPage.imagesWithText.map((proj) => (
                    <motion.div
                        key={proj.id}
                        className={`border-2 border-yellow-400 rounded-lg p-4 shadow-lg flex ${proj.isPortrait ? "flex-col lg:flex-row md:flex-row" : "flex-col"} items-left cursor-pointer ${proj.isFullWidth ? 'w-full sm:col-span-1 md:col-span-2' : ''}`}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => handleOpenModal(proj)}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {proj.isFullWidth ? (
                            <div className="flex w-full space-x-2">
                                <motion.img
                                    src={proj.image}
                                    alt={proj.title}
                                    className="w-1/2 h-full object-cover rounded-lg"
                                />
                                {proj.image2 && <motion.img
                                    src={proj.image2}
                                    alt={proj.title}
                                    className="w-1/2 h-full object-cover rounded-lg"
                                />}
                            </div>
                        ) : (
                            <motion.img
                                src={proj.image}
                                alt={proj.title}
                                className={`w-full h-full object-cover rounded-lg ${proj.title ? "mb-4" : "mb-0"}`}
                            />
                        )}
                        <div className={`text-left mt-3 ${proj.isPortrait ? " ml-5" : ""}`}>
                            {proj.title && <div className="flex items-center">
                                <h3 className={`text-xl font-bold ${themes[theme].text}`}>
                                    {"proj.title"}
                                </h3>
                            </div>}
                            <p className={`${proj.title ? "mt-0" : "mt-2"} ${themes[theme].subText}`}>
                                {proj.description}
                            </p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>

            {/* Images */}
            <div className="flex flex-wrap justify-center my-8">
                {detailPage.images.map((image) => (
                    <img
                        key={image.imgId}
                        src={image.image}
                        alt={`${image.imgId}`}
                        className={`m-4 ${image.isVertical === "true" ? "h-[30rem]" : "w-full h-auto"}`}
                        style={{ objectFit: 'contain' }}
                    />
                ))}
            </div>

            {/* Details Section */}
            {isSmallScreen ? (
                // This is shown on small screens
                <div className='flex flex-wrap justify-center px-4 sm:px-0'>
                    <div className="justify-start mt-8 max-w-full sm:max-w-lg">
                        {detailPage.details.map((detail, index) => (
                            <React.Fragment key={index}>
                                <span className="mb-2 flex flex-col sm:flex-row items-start sm:items-center">
                                    <h2 className="text-base sm:text-l font-semibold">● {detail.title} -&nbsp;</h2>
                                    <p className="text-base sm:text-l" style={{ color: themes[theme].subText }}>
                                        {detail.description}
                                    </p>
                                </span>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ) : (
                // This is shown on larger screens
                <div className='flex flex-wrap justify-center'>
                    <div className="justify-start mt-8">
                        {detailPage.details.map((detail, index) => (
                            <React.Fragment key={index}>
                                <span className="mb-2 flex items-center">
                                    <h2 className="text-l font-semibold">● {detail.title} -&nbsp;</h2>
                                    <p className="text-l" style={{ color: themes[theme].subText }}>
                                        {`${detail.description}`}
                                    </p>
                                </span>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
            {/* Modal for Enlarged Project */}
            <AnimatePresence>
                {selectedProj && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-gray bg-opacity-75 z-50"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        onClick={handleCloseModal}
                    >
                        <motion.div
                            className="relative p-4 bg-black bg-opacity-75 rounded-lg object-contain"
                            style={{
                                width: '90vw',
                                height: '90vh',
                                maxWidth: '1200px',
                                maxHeight: '90vh',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={selectedProj.image}
                                alt={selectedProj.title}
                                className="w-full h-full object-contain rounded-lg"
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ProjectDetails;
