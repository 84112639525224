import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { motion } from 'framer-motion';

const About = () => {
    const { theme, themes } = useContext(ThemeContext); // Use theme context

    return (
        <div className={`${themes[theme].background} ${themes[theme].text} flex items-start pt-8 pl-8 pr-8 mt-3`}>
            <div className="flex flex-col lg:flex-row w-full max-w-7xl mx-auto space-y-10 lg:space-y-0 lg:space-x-10"> {/* Added flex for side-by-side layout on large screens */}

                {/* Timeline Section */}
                <motion.div
                    className="lg:w-1/2 max-w-full" // Set width for side-by-side layout
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.5 }}
                >
                    <div className='text-center'>
                        <div>
                            <h2 className="text-2xl font-bold mb-10 lg:pr-20 lg:mr-20 text-center">Career Timeline</h2> {/* Increased heading size */}
                        </div>
                        <div className="relative border-l-4 border-gray-300 pl-8 ml-10  text-left"> {/* Made the line and spacing larger */}
                            {/* Timeline Item 1 */}
                            <div className="mb-16"> {/* Increased the margin for more spacing */}
                                <div className="absolute w-5 h-5 bg-gray-300 rounded-full -left-3 top-2"></div> {/* Increased timeline marker size */}
                                <p className="text-xl font-semibold">Next Great Company</p> {/* Increased text size */}
                                <p className="text-xl text-gray-500">19th Nov 2024 - ∞Y Exp</p> {/* Increased text size */}
                            </div>
                            {/* Timeline Item 2 */}
                            <div className="mb-16">
                                <p className="text-xl font-semibold">OpenBet</p>
                                <p className="text-xl text-gray-500">June 27 2022 - 2Y Exp</p>
                            </div>
                            {/* Timeline Item 3 */}
                            <div className="mb-16">
                                <p className="text-xl font-semibold">Liltriangle</p>
                                <p className="text-xl text-gray-500">April 27 2019 - 3.3Y Exp</p>
                            </div>
                        </div>
                    </div>
                </motion.div>

                {/* About Me Section */}
                <motion.div
                    className="lg:w-3/4 max-w-full text-left" // Set width for side-by-side layout
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.5 }}
                >
                    <h1 className="text-2xl font-bold mb-10 text-center">About Me</h1> {/* Increased heading size */}
                    <p className="text-base leading-relaxed mb-8">
                        I am a dedicated and experienced software engineer with over 5.5 years of expertise in iOS development.
                        My skills encompass a broad range of technologies:
                    </p>
                    <ul className="list-disc list-inside mt-6 text-base"> {/* Increased list item size and added more spacing */}
                        <li><strong>iOS Development:</strong> Proficient in creating robust and user-friendly applications.</li>
                        <li><strong>Xcode:</strong> Extensive experience with Apple's integrated development environment.</li>
                        <li><strong>UIKit:</strong> Skilled in building and customizing user interfaces for iOS applications.</li>
                        <li><strong>Swift:</strong> Expert in Swift programming language, ensuring efficient and maintainable code.</li>
                        <li><strong>SwiftUI:</strong> Adept at using SwiftUI for modern, declarative UI design.</li>
                        <li><strong>Mac OS Development:</strong> Proficient in creating robust and user-friendly applications.</li>
                    </ul>
                    <p className="mt-8 text-base leading-relaxed">
                        Throughout my career, I have consistently delivered high-quality applications that meet user needs and
                        business objectives. I am passionate about staying updated with the latest industry trends and continuously
                        improving my skill set to bring innovative solutions to life.
                    </p>
                    <div className='mt-9 text-base'><strong>Hobby :</strong> To create Robust Websites and Apps like this, interested in dividing and giving responsibilities to modules, such that each module is perfectly optimised to serve its purpose.</div>
                </motion.div>

            </div>
        </div>
    );
};

export default About;
