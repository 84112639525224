export const themes = {
    rose: {
        background: 'linear-gradient(135deg, #eee 10%, #af4261 90%, #fff)', // Smooth blend for vibrant light gradient
        text: 'text-black', // Title text color
        subText: 'text-gray-600', // Description text color
        modelColor: '#d09ba5', // Color for the 3D model
        mainTint: '#ffebf0',
        borderColor: '#000',
        isIconDark: true,
        glowColor: '#af4261',
        statusText: 'text-black-500'
    },
    dark: {
        background: 'linear-gradient(135deg, #0f2027 40%, #1a3a43 60%, #2c5364)', // Smooth blend for dark gradient
        text: 'text-white', // Title text color
        subText: 'text-gray-400', // Description text color
        modelColor: '#2c5364', // Dark color for the 3D model
        mainTint: '#2c5364',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#fff',
        statusText: 'text-white-500'
    },
    purple: {
        background: 'linear-gradient(135deg, #667eea 40%, #7367a8 60%, #764ba2)', // Smooth blend for purple gradient
        text: 'text-purple-200', // Title text color
        subText: 'text-purple-300', // Description text color
        modelColor: '#a084ca', // Purple color for the 3D model
        mainTint: '#fff',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#fff',
        statusText: 'text-white-500'
    },
    purpled: {
        background: 'linear-gradient(135deg, #000000 40%, #7367a8 60%, #764ba2)', // Smooth blend for purple gradient
        text: 'text-purple-200', // Title text color
        subText: 'text-purple-300', // Description text color
        modelColor: '#a084ca', // Purple color for the 3D model
        mainTint: '#764ba2',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#a084ca',
        statusText: 'text-white-500'
    },
    universe: {
        background: 'linear-gradient(135deg, #000000 40%, #944645 60%, #764ba2)', // Smooth blend for purple gradient
        text: 'text-purple-200', // Title text color
        subText: 'text-purple-300', // Description text color
        modelColor: '#a084ca', // Purple color for the 3D model
        mainTint: '#764ba2',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#d09ba5',
        statusText: 'text-green-500'
    },
    rainbow: {
        background: 'linear-gradient(135deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff)', // Rainbow gradient transitioning through all colors
        text: 'text-black', // White text to stand out against the colorful background
        subText: 'text-gray-900', // Gray text for descriptions to keep focus on the title
        modelColor: '#ffff00', // Bright yellow for a striking contrast with the rainbow colors
        mainTint: '#0000ff',
        borderColor: '#000',
        isIconDark: true,
        glowColor: '#000',
        statusText: 'text-black-500'
    },
    blue: {
        background: 'linear-gradient(135deg, #000000 40%, #3383ff 60%, #0072ff)', // Smooth blend for cool blue gradient
        text: 'text-white', // Title text color
        subText: 'text-blue-200', // Description text color
        modelColor: '#0072ff', // Blue color for the 3D model
        mainTint: '#0072ff',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#0072ff',
        statusText: 'text-black-500'
    },
    sunset: {
        background: 'linear-gradient(135deg, #000000 40%, #f6b05c 60%, #f2c94c)', // Smooth blend for warm sunset gradient
        text: 'text-white', // Title text color
        subText: 'text-yellow-200', // Description text color
        modelColor: '#f2c94c', // Warm sunset color for the 3D model
        mainTint: '#f2c94c',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#f2c94c',
        statusText: 'text-black-500'
    },
    red: {
        background: 'linear-gradient(135deg, #000000 40%, #550000 70%, #ff0000)', // Smooth blend of black and red
        text: 'text-white', // Title text color
        subText: 'text-gray-300', // Description text color
        modelColor: '#ff0000', // Red color for the 3D model
        mainTint: '#ff0000',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#ff0000',
        statusText: 'text-green-500'
    },
    emerald: {
        background: 'linear-gradient(135deg, #000000 40%, #006600 70%, #00cc00)', // Smooth blend of dark to light emerald green
        text: 'text-white', // Title text color
        subText: 'text-gray-200', // Description text color
        modelColor: '#00cc00', // Emerald green color for the 3D model
        mainTint: '#00cc00',
        borderColor: '#fff',
        isIconDark: false,
        glowColor: '#00cc00',
        statusText: 'text-green-500'
    }
};