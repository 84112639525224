import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../../context/ThemeContext';

const ResumeButton = ({ title = 'Resume', link, onClick, className = '', style = {} }) => {
    const { theme, themes } = useContext(ThemeContext);
    const glowColor = `${themes[theme].glowColor}`;

    return link ? (
        // Render <a> tag if link is provided
        <motion.a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-xl md:text-xl mt-6 mb-5 px-6 py-2 ${themes[theme].text} border border-red-400 rounded-md hover:border-yellow-500 transition-all duration-300 ease-in-out shadow-sm ${className}`}
            style={{
                display: 'inline-block',
                padding: '2.5px 30px',
                color: themes[theme].text,
                letterSpacing: '0.5px',
                borderRadius: '8px',
                border: `1px solid ${themes[theme].glowColor || 'rgba(255, 0, 0, 0.8)'}`,
                backgroundColor: 'transparent',
                position: 'relative',
                zIndex: 5,
                overflow: 'hidden',
                textDecoration: 'none',
                boxShadow: `0 0 5px ${themes[theme].glowColor || 'rgba(255, 0, 0, 0.8)'}`,
                transition: 'all 0.4s ease',
                cursor: 'pointer',
                ...style, // Custom styles passed from props
            }}
            whileHover={{
                scale: 1.06,
                boxShadow: `0 0 10px ${glowColor}, 0 0 20px ${glowColor}, 0 0 30px ${glowColor}`,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
        >
            {title}
        </motion.a>
    ) : (
        // Render <button> tag if no link is provided
        <motion.button
            onClick={onClick}
            className={`${className}`}
            style={{
                display: 'inline-block',
                padding: '2.5px 30px',
                color: themes[theme].text,
                letterSpacing: '0.5px',
                borderRadius: '8px',
                border: `1px solid ${themes[theme].glowColor || 'rgba(255, 0, 0, 0.8)'}`,
                backgroundColor: 'transparent',
                position: 'relative',
                zIndex: 5,
                overflow: 'hidden',
                boxShadow: `0 0 5px ${themes[theme].glowColor || 'rgba(255, 0, 0, 0.8)'}`,
                transition: 'all 0.4s ease',
                cursor: 'pointer',
                ...style, // Custom styles passed from props
            }}
            whileHover={{
                scale: 1.06,
                boxShadow: `0 0 10px ${glowColor}, 0 0 20px ${glowColor}, 0 0 30px ${glowColor}`,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
        >
            {title}
        </motion.button>
    );
};

export default ResumeButton;
