import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../context/ThemeContext';
import TypewriterText from '../components/styled/TypewriterText';
import { loadSlim } from "@tsparticles/slim";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { socialLinks as socials } from '../data/socialLinks'; // Import the projects array
import SocialLinks from '../components/SocialLinks';
import ResumeButton from '../components/styled/ResumeButton';

// Dynamically import your 3D Model
const ThreeDModel = React.lazy(() => import('../components/ThreeDModel'));

const pageVariants2 = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { when: "beforeChildren", staggerChildren: 0.3 }
    }
};

const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1 } },
};

const Home = () => {
    const [init, setInit] = useState(false);
    const { theme, themes, particleOption, particleOptions } = useContext(ThemeContext);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const homeParticleOptions = useMemo(() => {
        if (!themes || !theme || !themes[theme]) return {};
        return particleOptions[particleOption];
    }, [themes, theme, particleOptions, particleOption]);

    return (
        <>
            <Helmet>
                <title>Shivam Patel IOS</title>
                <meta name="description" content="Welcome to my portfolio website!" />
            </Helmet>

            {/* Particles Background */}
            {init && (
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={homeParticleOptions}
                />
            )}

            {/* Main Section */}
            <section
                className={`flex flex-col justify-start items-center mb-5 p-0 mt-9 text-center ${themes[theme].text}`}
                style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                }}
            >
                <TypewriterText text="Shivam Patel" />
                <motion.p
                    className={`text-2xl md:text-3xl ${themes[theme].subtext}`}
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    Software Engineer - iOS
                </motion.p>
                <motion.p
                    className={`text-xl mt-2 md:text-xl mb-0 ${themes[theme].subtext}`}
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    Founder - Codesios
                </motion.p>

                <motion.div
                    className="flex flex-wrap justify-start mb-0"
                    initial="hidden"
                    animate="visible"
                    variants={pageVariants2} // Stagger the animation of each project card
                >
                    {socials.map((social, index) => (
                        <SocialLinks
                            key={index}
                            socialId={social.id}
                            imgSrc={social.imgSrc}
                            linkURL={social.linkURL}
                        />
                    ))}
                </motion.div>

                {/* Resume Button with Delay */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: socials.length * 0.4 }} // Delay based on number of socials
                >
                    <ResumeButton link={`https://drive.google.com/file/d/1Z6aTkL4lWW3jdYMBA6N6rGBSBjVB98pC/view?usp=sharing`} />
                </motion.div>
            </section>

            {/* 3D Model Section */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: socials.length * 0.5 }} // Delay based on number of socials
            >
                <Suspense fallback={null}>
                    <ThreeDModel />
                </Suspense >
            </motion.div>
        </>
    );
};

export default Home;
