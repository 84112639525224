import React, { useState, useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import ResumeButton from '../components/styled/ResumeButton';
import { db } from '../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'; // FontAwesome spinner icon
// Animation variant for the error shake effect
const shakeVariant = {
    shake: {
        x: [-10, 10, -10, 10, -10, 10, -10, 0], // Creates a shake effect
        transition: {
            duration: 0.5, // Duration of the shake
            ease: 'easeInOut',
        },
    },
};
// Animation variants
const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            ease: 'easeInOut',
        },
    },
};
const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
        },
    },
};

const Contact = () => {
    const { theme, themes } = useContext(ThemeContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false); // Loader state

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !email || !message) {
            setStatus('Please fill in all fields.');
            return;
        }

        setLoading(true); // Start the loader

        try {
            await addDoc(collection(db, 'messages'), {
                name: name,
                email: email,
                message: message,
                timestamp: new Date(),
            });

            setName('');
            setEmail('');
            setMessage('');
            setStatus('Message sent successfully!');
        } catch (error) {
            console.error("Error adding message: ", error);
            setStatus('Failed to send message. Please try again.');
        } finally {
            setLoading(false); // Stop the loader
        }
    };

    return (
        <div className={`${themes[theme].background} ${themes[theme].text} flex flex-col items-center justify-start p-6`}>
            <motion.div
                className="rounded-lg shadow-lg p-8 max-w-md w-full border-2 border-gray-300"
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.09)',
                    backdropFilter: 'blur(10px)',
                    WebkitBackdropFilter: 'blur(10px)',
                }}
                variants={status === "Please fill in all fields." ? shakeVariant : containerVariants}
                animate={status === "Please fill in all fields." ? "shake" : "visible"}
                initial="hidden"
            >
                <motion.h1
                    className={`text-2xl font-bold text-center ${themes[theme].text} mb-5 mt-0`}
                    variants={itemVariants}
                    initial="visible"
                >
                    Contact Me
                </motion.h1>

                <motion.form
                    className="space-y-4"
                    onSubmit={handleSubmit}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.div variants={itemVariants}>
                        <input
                            type="text"
                            placeholder="Your Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ backgroundColor: "transparent" }}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </motion.div>

                    <motion.div variants={itemVariants}>
                        <input
                            type="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ backgroundColor: 'transparent' }}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </motion.div>

                    <motion.div variants={itemVariants}>
                        <textarea
                            placeholder="Your Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ backgroundColor: 'transparent' }}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                        ></textarea>
                    </motion.div>

                    <motion.div className="text-center mb-0 mt-0">
                        {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin className="text-blue-500 text-xl" />
                        ) : (
                            <ResumeButton title="Send Message" className={`mt-3 mb-1 ${themes[theme].text}`} />
                        )}
                    </motion.div>

                    {status && (
                        <motion.div
                            className={`text-center ${themes[theme].statusText} mt-9 pt-4 text-[16px] flex items-center justify-center`}
                            animate={status === "Please fill in all fields." ? "visible" : "visible"} // Apply the shake variant
                            variants={status === "Please fill in all fields." ? itemVariants : itemVariants} // Use shakeVariant if error
                        >
                            <span>{status}</span>
                            {status === "Message sent successfully!" && (
                                <FontAwesomeIcon icon={faCheckCircle} className={`mb-1 ${themes[theme].statusText} ml-2`} />
                            )}
                            {status === "Please fill in all fields." && (
                                <FontAwesomeIcon icon={faExclamationCircle} className={`mb-1 ${themes[theme].statusText} ml-2`} />
                            )}
                        </motion.div>
                    )}
                </motion.form>
            </motion.div>
        </div>
    );
};

export default Contact;
