// certificates.js
export const certificates = [
    {
        id: 1,
        title: 'iOS Mobile Application Development',
        description: 'Issued by Meta, Verify at : https://coursera.org/verify/CJZF2FLUEKUL',
        image: '/assets/certificates/MetaAppDeveloper.png',
    },
    {
        id: 2,
        title: 'Data structures & Algo in Swift',
        description: 'Issued by Udemy, Instructor - Jonathan Rasmusson - Bootcamp on DSA',
        image: '/assets/certificates/swift_dsa_udemy.jpg',
    },
    {
        id: 3,
        title: 'Advanced IOS Development Paid - Live Google Meet Classes',
        description: 'Issued by Yogesh Patel, Successfully completing Advanced Course of IOS development',
        image: '/assets/certificates/Shivam_Yogesh_Advanced.png',
    },
    {
        id: 4,
        title: 'Basic IOS Development Paid - Live Google Meet Classes',
        description: 'Issued by Yogesh Patel, Successfully completing Basics of IOS development',
        image: '/assets/certificates/Shivam_Yogesh_Beginner.png',
    },
    {
        id: 5,
        title: 'Swift & Objective C Course',
        description: 'Issued By Udemy Guided By Aaron Caines IOS 12 and Xcode 10 - Swift 4.2 And Objective C. 72 Hours of Video Lectures.',
        image: '/assets/certificates/UdemySwiftAaron.png',
    },
    {
        id: 6,
        title: 'Swift Basic Problem Solving',
        description: 'Issued by HackerRank, Successfully completing Problem Solving Basic',
        image: '/assets/certificates/HackerRankProblemBasic.png',
    },
    {
        id: 7,
        title: '100 days of Swift',
        description: 'Issued by Hacking With Swift, deep dive with Swift',
        image: '/assets/certificates/100daysOfSwift.jpg',
    },
    {
        id: 8,
        title: 'React.js Frontend Developer',
        description: 'Issued by HackerRank, Frontend Development in Reactjs',
        image: '/assets/certificates/HackrankReactFrontend.png',
    },
    {
        id: 9,
        title: 'Canva Designer',
        description: 'Issued by Canva, completing 100 Designs with Canva',
        image: '/assets/certificates/CanvaDesignMilestoneBadge100.jpg',
    },
    // Add more certificates as needed
];