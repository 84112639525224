import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from '../context/ThemeContext';
import { courseProjects } from '../data/courseProjects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

// Modal animation variants
const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: "spring", stiffness: 300, damping: 25 }
    },
    exit: {
        opacity: 0,
        scale: 0.8,
        transition: { duration: 0.3 }
    }
};

// Stagger animation for the project grid
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2, // Adds a delay between each child animation
        },
    },
};

const CourseProjects = () => {
    const { theme, themes } = useContext(ThemeContext);
    const [selectedProj, setSelectedProj] = useState(null);

    const handleOpenModal = (proj) => {
        setSelectedProj(proj);
    };

    const handleCloseModal = () => {
        setSelectedProj(null);
    };

    return (
        <div className='pt-3'>
            <h1 className={`text-center text-xl mb-8 ${themes[theme].text}`}>•         •           •           •</h1>
            <h6 className={`text-l ml-5 pt-9 mt-9 ${themes[theme].text}`}>**Please Note All the Below projects are published publicly on GitHub.</h6>
            <h3 className={`text-l ml-5 mb-0 ${themes[theme].text}`}>**GitHub Link- https://github.com/shivampatel066</h3>
            <h3 className={`text-l ml-5 mb-9 ${themes[theme].text}`}>**Some of the below apps are also coded in Obj C, while most of the apps are in Swift, and Developed using Xcode.</h3>

            {/* Projects Grid */}
            <motion.div
                className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                {courseProjects.map((proj) => (
                    <motion.div
                        key={proj.id}
                        className={`border-2 border-yellow-400 rounded-lg p-4 shadow-lg flex ${proj.isPortrait ? "flex-col  md:flex-row lg:flex-row col-span-1 md:col-span-2 lg:col-span-2" : "flex-col"} items-left cursor-pointer ${proj.isFullWidth ? 'w-full xs:col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-2' : ''}`}
                        whileHover={{ scale: 1.05 }}
                        onClick={() => handleOpenModal(proj)}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {proj.isFullWidth ? (
                            <div className="flex w-full space-x-2">
                                <motion.img
                                    src={proj.image}
                                    alt={proj.title}
                                    className="w-1/2 h-full object-cover rounded-lg"
                                />
                                {proj.image2 && <motion.img
                                    src={proj.image2}
                                    alt={proj.title}
                                    className="w-1/2 h-full object-cover rounded-lg"
                                />}
                            </div>
                        ) : (
                            <motion.img
                                src={proj.image}
                                alt={proj.title}
                                className="w-full h-full object-cover rounded-lg mb-4"
                            />
                        )}
                        <div className={`text-left mt-3 ${proj.isPortrait ? " ml-5" : ""}`}>
                            <div className="flex items-center">
                                <h3 className={`text-xl font-bold ${themes[theme].text}`}>
                                    {proj.title}
                                </h3>
                                <a
                                    href={proj.link1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`ml-auto flex items-center mb-1 py-1 px-2 text-white bg-black rounded ${themes[theme].subText} hover:bg-gray-800`}
                                >
                                    <FontAwesomeIcon icon={faGithub} className="mr-2" />
                                    GitHub
                                </a>
                            </div>
                            <p className={`mt-2 ${themes[theme].subText}`}>
                                {proj.description}
                            </p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>

            {/* Modal for Enlarged Project */}
            <AnimatePresence>
                {selectedProj && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-gray bg-opacity-75 z-50"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        onClick={handleCloseModal}
                    >
                        <motion.div
                            className="relative p-4 bg-black bg-opacity-75 rounded-lg object-contain"
                            style={{
                                width: '90vw',
                                height: '90vh',
                                maxWidth: '1200px',
                                maxHeight: '90vh',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={selectedProj.image}
                                alt={selectedProj.title}
                                className="w-full h-full object-contain rounded-lg"
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CourseProjects;
