// courseProjects.js
export const courseProjects = [
    {
        id: 1,
        title: 'Guess Color',
        description: 'An interactive Game of Guessing the Colour from their RGB components with 3 different levels of difficulty to choose from.',
        image: '/assets/courses/rgbGame.png',
        link1: 'https://github.com/shivampatel066/GuessColor'
    },
    {
        id: 2,
        title: 'Temperature Converter',
        description: 'Simple temperature converter app from Fahrenheit to Celsius and Vise Versa, try it out by clicking github link.',
        image: '/assets/courses/temperature.png',
        link1: 'https://github.com/shivampatel066/TemperatureCalculator'
    },
    {
        id: 3,
        title: 'MyCalculator',
        description: 'Calculator with Conversions like Currency Converter, Length, Area, Volume, Temperature, Speed, Time, Mass for IOS.',
        image: '/assets/courses/Calculator1.jpg',
        image2: '/assets/courses/Calculator2.png',
        link1: 'https://github.com/shivampatel066/MyCalculator',
        isFullWidth: true
    },
    {
        id: 4,
        title: 'DoodleBug App',
        description: 'Fully featured Doodle Drawing Application with RGB color, opacity and brush size adjustment sliders available in the settings menu.',
        image: '/assets/courses/Doodle.png',
        link1: 'https://github.com/shivampatel066/DoodleBug',
        isFullWidth: true
    },
    {
        id: 5,
        title: 'Roll The Dice',
        description: 'Randomly Roll the Dice either select one or two dice to be thrown, can be used while playing Monopoly Board Game.',
        image: '/assets/courses/DiceRoll.png',
        link1: 'https://github.com/shivampatel066/RollofaDice'
    },
    {
        id: 6,
        title: 'Swipe As Told',
        description: 'Swipe Quickly as instructed, for each correct swipe you get 1 point, for each wrong swipe 1 point gets deducted.',
        image: '/assets/courses/SimonSwipe.png',
        link1: 'https://github.com/shivampatel066/SwipeAsTold'
    },
    {
        id: 7,
        title: 'SnapAttack',
        description: 'Reaction Game, Two cards are generated randomly on the screen, click as soon as both the card matches and points are increased or decreased based on the correctness of your clicks. ',
        image: '/assets/courses/CardSelect.png',
        link1: 'https://github.com/shivampatel066/SnapAttack'
    },
    {
        id: 8,
        title: 'Shake Me Silly',
        description: 'Shake the device to get points, with an animated smiley changes position along with the points.',
        image: '/assets/courses/ShakeMeSilly.png',
        link1: 'https://github.com/shivampatel066/ShakeMeSilly'
    },
    {
        id: 9,
        title: 'Traffic Signal',
        description: 'Press the Stop button as soon as the Signal turns green, get the grade based on your click timings.',
        image: '/assets/courses/Signal.png',
        link1: 'https://github.com/shivampatel066/TrafficSignal'
    },
    {
        id: 10,
        title: 'Tap Me Fast',
        description: 'Press the Tap button to get points, points increases by 1 for each tap, get the grade based on points.',
        image: '/assets/courses/TapMeFast.png',
        link1: 'https://github.com/shivampatel066/TapMeFast'
    },
    {
        id: 11,
        title: 'Android App - Lion Or Tiger?',
        description: 'A fun and engaging Tic-Tac-Toe game designed for Android, where Player 1 takes on the role of a Lion, and Player 2 becomes a Tiger. The game includes smooth animations and intuitive controls that make the experience enjoyable for all ages. Players can interact with the game in real-time, choosing their symbols with visual feedback as the lion and tiger icons battle for victory. Perfect for quick gameplay sessions and a unique twist on the classic game, bringing a little animal kingdom rivalry to your fingertips.',
        image: '/assets/courses/TicTacToe.gif',
        link1: 'https://github.com/shivampatel066/LionOrTige',
        isPortrait: true
    }
    // Add more Course Projects as needed
];