import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../context/ThemeContext';
import { Link } from 'react-router-dom'; // Import Link

const cardVariants = {
    hidden: { opacity: 0, scale: 0.8, rotate: 10 },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: { type: 'spring', stiffness: 120 }
    }
};

const ZoominAppLogo = ({ projectId, imgSrc, title, isPremium }) => {
    const { theme, themes } = useContext(ThemeContext);

    return (
        <motion.div
            className="zoom text-center p-4"
            variants={cardVariants}
            whileHover={{ scale: 1.1, rotate: -5 }}
            transition={{ duration: 0.2 }}
        >
            <Link to={`/projects/${projectId}`} style={{ textDecoration: 'none' }}> {/* Using Link for navigation */}
                <motion.img
                    src={imgSrc}
                    alt={title}
                    className="img"
                    // style={{ borderRadius: '25%', width: '150px', height: '150px', cursor: 'pointer' }}
                    loading="eager"
                    style={{
                        width: '150px', height: '150px',
                        color: themes[theme].text,
                        borderRadius: '15%',
                        position: 'relative',
                        zIndex: 1,
                        overflow: 'hidden',
                        boxShadow: `0 0 20px rgb(0,0,0,0)`,
                        transition: 'all 0.1s ease',
                        cursor: 'pointer'
                    }}
                    whileHover={{
                        scale: 1,
                        boxShadow: `0px 0px 40px 10px ${themes[theme].glowColor || 'rgba(255, 0, 0, 1)'}`,
                    }}
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1, transition: { duration: 0 } }}
                />
                <p className={themes[theme].text} style={{ marginTop: '5px' }}>
                    {title}
                </p>
                {isPremium && (
                    <span
                        style={{
                            display: 'inline-block',
                            marginTop: '0px',
                            padding: '0px 0px',
                            color: '#F4C430',
                            fontSize: '0.8em',
                            fontWeight: 'bold'
                        }}
                    >
                        Premium
                    </span>
                )}
            </Link>
        </motion.div>
    );
};

export default ZoominAppLogo;
