import React from 'react';

const DownloadAppButton = ({ appStoreLink }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                <img
                    src="/assets/apps/appstoreLogo.png"
                    alt="AppstoreLogo"
                    style={{ width: '200px', objectFit: 'contain', textAlign: 'center' }}
                />
            </a>
        </div>
    );
};

export default DownloadAppButton;
