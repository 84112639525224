import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from '../context/ThemeContext';

const cardVariants = {
    hidden: { opacity: 0, scale: 0.8, rotate: 10 },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: { type: 'spring', stiffness: 120 }
    }
};

const SocialLinks = ({ socialId, imgSrc, linkURL }) => {
    const { theme, themes } = useContext(ThemeContext);
    const [isHovered, setIsHovered] = useState(false);

    // Dynamically set imgSrc based on theme's isIconDark property
    const imgSource = themes[theme].isIconDark
        ? `${imgSrc}_dark.png`
        : `${imgSrc}_light.png`;

    // Define the glow color based on the theme or use a default color
    const glowColor = `${themes[theme].glowColor}`

    return (
        <motion.div
            className="zoom text-center p-4 pb-0 mb-0 mt-6"
            variants={cardVariants}
            whileHover={{ scale: 1.1, rotate: -5 }}
            transition={{ duration: 0.2 }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <a key={socialId} href={linkURL} target="_blank" rel="noopener noreferrer">
                <motion.img
                    src={imgSource}
                    alt={`${socialId} icon`}
                    className="social-icon"
                    style={{
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                        boxShadow: isHovered
                            ? `0 0 15px ${glowColor}, 0 0 30px ${glowColor}, 0 0 45px ${glowColor}`
                            : 'none',
                        transition: 'box-shadow 0.3s ease',
                    }}
                    whileHover={{ scale: 1.2 }}
                    loading="eager"
                />
                {/* Display the socialId on hover */}
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={isHovered ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    className="social-id-label"
                    style={{
                        color: themes[theme].text,
                        marginTop: '8px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    {socialId}
                </motion.div>
            </a>
        </motion.div>
    );
};

export default SocialLinks;
